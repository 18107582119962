import request from '@/utils/https'
// 创建聊天
export const chatGroup = (data) => {
  return request('customerservice/chat_group_user', 'post', data)
}
// 一对一添加聊天记录
export const chatLogSingle = (data) => {
  return request('customerservice/chat_log_single', 'post', data)
}
// 群聊添加聊天记录
export const chatLogGroup = (data) => {
  return request('customerservice/chat_log_group', 'post', data)
}
// 分页查询聊天记录
export const chatLogPage = (data) => {
  return request('customerservice/chat_log_page', 'get', data)
}
// 聊天列表
export const chatGroupPage = (data) => {
  return request('customerservice/chat_group_page', 'get', data)
}
// 获取随机聊天客服
export const getChatService = (data) => {
  return request('customerservice/get_chat_service', 'get', data)
}
// 消息已读
export const operateMsg = (data) => {
  return request('customerservice/operate_msg', 'post', data)
}
// 查看群成员列表
export const groupMembers = (data) => {
  return request('customerservice/group_members', 'get', data)
}
// 退出群聊
export const exitGroup = (data) => {
  return request('customerservice/exit_group', 'post', data)
}
// 会话操作
export const operateGroup = (data) => {
  return request('customerservice/operate_group', 'post', data)
}
