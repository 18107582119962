<template>
  <div>
    <el-dialog v-model="visible" :title="dialogTitle" width="464px" :show-close="false">
      <div v-if="formShow">
        <div class="input">
          <p>报价</p>
          <input
            type="text"
            :placeholder="placeholder(props.goodsInfo.incr_price)"
            v-model="inputValue"
          />
        </div>
        <p style="margin-top: 10px">当前商品价格为：{{ props.goodsInfo.price / 100 }}元</p>
        <p style="margin-top: 5px" v-if="props.goodsInfo.last_offer > 0">
          我的上次出价为：{{ props.goodsInfo.last_offer / 100 }}元
        </p>
        <div class="footer">
          <div class="footer-btn">
            <div class="cancel" @click="cancel">取消</div>
            <div class="submit" @click="submit">提交</div>
          </div>
        </div>
      </div>
      <div class="success-box" v-else>
        <img src="@/assets/images/success-icon.png" alt="" />
        <p>恭喜您，报价成功</p>
        <div class="success-btn" @click="certainly">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, defineExpose } from 'vue'
import { biddingOffer } from '@/api/goods'
import { ElMessage } from 'element-plus'
const props = defineProps({
  goodsInfo: Object
})
const visible = ref(false)
const dialogTitle = ref('竞拍报价')
const emit = defineEmits(['clickChild'])
const placeholder = (e) => {
  return '每次加价不得低于' + e / 100 + '元'
}
const inputValue = ref('')
const formShow = ref(true)
const cancel = () => {
  visible.value = false
}
const submit = () => {
  if (inputValue.value >= props.goodsInfo.incr_price / 100) {
    let params = {
      goods_id: props.goodsInfo.id,
      price: Number(inputValue.value) * 100
    }
    biddingOffer(params).then((res) => {
      if (res.code == 200) {
        formShow.value = false
        inputValue.value = ''
        dialogTitle.value = ''
        emit('clickChild')
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    ElMessage.warning('请输入正确金额')
  }
}
const certainly = () => {
  visible.value = false
  formShow.value = true
  dialogTitle.value = '竞拍报价'
}
onMounted(() => {})
defineExpose({ visible })
</script>
<style lang="less" scoped>
:deep(.el-dialog) {
  border-radius: 20px;
}
:deep(.el-dialog__header) {
  text-align: center;
  border: 0;
  border-radius: 20px 20px 0 0;
  margin-right: 0;
  .el-dialog__title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
  }
}
.input {
  display: flex;
  width: 400px;
  height: 34px;
  border-radius: 8px;
  border: 1px solid #0cb5ff;
  margin: 0;
  p {
    width: 80px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 8px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  input {
    width: 200px;
    margin-left: 9px;
  }
}
.footer {
  margin-top: 24px;
  .footer-btn {
    display: flex;
    justify-content: center;
  }
  .cancel {
    width: 136px;
    height: 41px;
    text-align: center;
    line-height: 41px;
    background: #f4f4f4;
    border-radius: 21px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
  }
  .submit {
    width: 136px;
    height: 41px;
    text-align: center;
    line-height: 41px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 21px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-left: 30px;
    cursor: pointer;
  }
}
.success-box {
  text-align: center;
  p {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
    margin: 20px 0;
  }
  .success-btn {
    width: 204px;
    height: 50px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 26px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
