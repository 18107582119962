<template>
  <div>
    <el-dialog v-model="props.dialogVisible" title="" :show-close="false">
      <div class="dialog-warp">
        <p class="tip">需支付商品金额为</p>
        <div class="amount">
          <text>¥</text>
          <text>{{ props.price / 100 }}</text>
        </div>
        <el-radio-group v-model="radio" class="ml-4">
          <el-radio label="1" size="large">
            <img src="@/assets/images/pay-alipay.png" alt="" />
            <text>支付宝</text>
          </el-radio>
          <el-radio label="2" size="large">
            <img src="@/assets/images/pay-balance.png" alt="" />
            <text>余额({{ props.balance / 100 }})</text>
          </el-radio>
        </el-radio-group>
        <div v-html="alipayHtml"></div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="submit">支付</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, nextTick } from 'vue'
import { balancePay } from '@/api/goods'
import { orderSign } from '@/api/order'
const props = defineProps({
  dialogVisible: Boolean,
  orderNo: String,
  price: Number,
  balance: Number
})
const emit = defineEmits(['clickChild'])
const radio = ref('')
const alipayHtml = ref('')
const close = () => {
  emit('clickChild', false)
}
const submit = () => {
  if (radio.value === '1') {
    orderSign({ order_no: props.orderNo, type: 1 }).then((res) => {
      alipayHtml.value = res
      nextTick(() => {
        document.forms['alipaysubmit'].target = '_blank'
        document.forms['alipaysubmit'].submit()
        alipayHtml.value = ''
      })
    })
  } else if (radio.value === '2') {
    balancePay({ order_no: props.orderNo }).then((res) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        emit('clickChild', false)
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}
</script>
<style lang="less" scoped>
:deep(.el-dialog) {
  width: 432px;
  border-radius: 20px;
  .el-dialog__header {
    display: none;
  }
}
.dialog-warp {
  text-align: center;
  :deep(.el-radio) {
    width: 12.5rem;
    height: 60px;
    line-height: 60px;
    border-radius: 8px;
    border: 2px solid #e6e6e6;
    justify-content: center;
  }
  .tip {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #b3b3b3;
  }
  .amount {
    margin-top: 12px;
    margin-bottom: 12px;
    text:nth-child(1) {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff211b;
    }
    text:nth-child(2) {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff211b;
    }
  }
}
</style>
