<template>
  <div>
    <el-dialog v-model="props.dialogVisible" title="" :show-close="false">
      <div class="dialog-wpar" v-if="props.type === 0">
        <p class="title">是否取消订单</p>
        <div class="btn-box">
          <p @click="close">取消</p>
          <p @click="submit">确定</p>
        </div>
      </div>
      <div class="dialog-wpar" v-if="props.type === 1">
        <p class="title">确认收货</p>
        <p class="text">请确保货物收到无误</p>
        <div class="btn-box">
          <p @click="close">取消</p>
          <p @click="submit">确定</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { cancelOrder, orderReceipt } from '@/api/order'
import { ElMessage } from 'element-plus'
const props = defineProps({
  dialogVisible: Boolean,
  orderId: Number,
  type: Number
})
const emit = defineEmits(['clickChild'])
const submit = () => {
  if (props.type === 0) {
    cancelOrder({ order_id: props.orderId }).then((res) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        emit('clickChild', false)
      } else {
        ElMessage.error(res.msg)
        emit('clickChild', false)
      }
    })
  } else if (props.type === 1) {
    orderReceipt({ id: props.orderId }).then((res) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        emit('clickChild', false)
      } else {
        ElMessage.error(res.msg)
        emit('clickChild', false)
      }
    })
  }
}
const close = () => {
  emit('clickChild', false)
}
</script>
<style lang="less" scoped>
:deep(.el-dialog) {
  width: 368px;
  border-radius: 20px;
  .el-dialog__header {
    display: none;
  }
}
.dialog-wpar {
  text-align: center;
  .title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
  }
  .text {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    margin-top: 14px;
  }
  .btn-box {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    p:nth-child(1) {
      width: 138px;
      height: 41px;
      background: #f4f4f4;
      border-radius: 21px;
      text-align: center;
      line-height: 41px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    p:nth-child(2) {
      width: 138px;
      height: 41px;
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
      border-radius: 21px;
      text-align: center;
      line-height: 41px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
